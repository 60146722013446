import React, { useEffect } from "react";
import {
  makeStyles,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
  IconButton,
  GridListTile,
  GridList,
  Button,
  AppBar,
  Box,
  ListItemText,
  List,
  ListItem,
  Typography
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Class_2_Sample_QP from "./images/sample_question_paper/Class_2_Sample_QP.jpg";
import Class_3_Sample_QP from "./images/sample_question_paper/Class_3_Sample_QP.jpg";
import Class_4_Sample_QP from "./images/sample_question_paper/Class_4_Sample_QP.jpg";
import Class_5_Sample_QP from "./images/sample_question_paper/class5Qp.png";
import Class_2_Mobile from "./images/sample_question_paper/Class_2_Mobile.jpg";
import Class_3_Mobile from "./images/sample_question_paper/Class_3_Mobile.jpg";
import Class_4_Mobile from "./images/sample_question_paper/Class_4_Mobile.jpg";
import agcLogo from "./images/AGCLogo.svg";
import sipAbacusLogo from "./images/sip-academy-logo1.png";
import plusLeft from "./images/plusSignLeft.svg";
import plusRight from "./images/plusSignRight.svg";
import backgroudImage from "./images/background2.svg";
import mobileBG from "./images/mobileBG.svg";
import saveAs from "file-saver";
import class1SamplePdf from "./images/sample_question_paper/AGC_Question_Paper_Class_1.pdf";
import class2SamplePdf from "./images/sample_question_paper/AGC_Question_Paper_Class_2.pdf";
import class3SamplePdf from "./images/sample_question_paper/AGC_Question_Paper_Class_3.pdf";
import class4SamplePdf from "./images/sample_question_paper/AGC_Question_Paper_Class_4.pdf";
import class5SamplePdf from "./images/sample_question_paper/AGC_Question_Paper_Class_5.pdf";
import round2class1SamplePdf from "./images/sample_question_paper/AGC_2022_R2_QP_Class_1.pdf";
import round2class2SamplePdf from "./images/sample_question_paper/AGC_2022_R2_QP_Class_2.pdf";
import round2class3SamplePdf from "./images/sample_question_paper/AGC_2022_R2_QP_Class_3.pdf";
import round2class4SamplePdf from "./images/sample_question_paper/AGC_2022_R2_QP_Class_4.pdf";
import round2class5SamplePdf from "./images/sample_question_paper/AGC_2022_R2_QP_Class_5.pdf";
import round3class1SamplePdf from "./images/sample_question_paper/AGC_2022_R3_QP_Class_1.pdf";
import round3class2SamplePdf from "./images/sample_question_paper/AGC_2022_R3_QP_Class_2.pdf";
import round3class3SamplePdf from "./images/sample_question_paper/AGC_2022_R3_QP_Class_3.pdf";
import round3class4SamplePdf from "./images/sample_question_paper/AGC_2022_R3_QP_Class_4.pdf";
import round3class5SamplePdf from "./images/sample_question_paper/AGC_2022_R3_QP_Class_5.pdf";
import round4class1SamplePdf from "./images/sample_question_paper/AGC_2022_R4_QP_Class_1.pdf";
import round4class2SamplePdf from "./images/sample_question_paper/AGC_2022_R4_QP_Class_2.pdf";
import round4class3SamplePdf from "./images/sample_question_paper/AGC_2022_R4_QP_Class_3.pdf";
import round4class4SamplePdf from "./images/sample_question_paper/AGC_2022_R4_QP_Class_4.pdf";
import round4class5SamplePdf from "./images/sample_question_paper/AGC_2022_R4_QP_Class_5.pdf";
//import round5class1SamplePdf from "./images/sample_question_paper/AGC_2022_R5_QP_Class_1.pdf"
//import round5class2SamplePdf from "./images/sample_question_paper/AGC_2022_R5_QP_Class_2.pdf"
//import round5class3SamplePdf from "./images/sample_question_paper/AGC_2022_R5_QP_Class_3.pdf"
//import round5class4SamplePdf from "./images/sample_question_paper/AGC_2022_R5_QP_Class_4.pdf"
//import round5class5SamplePdf from "./images/sample_question_paper/AGC_2022_R5_QP_Class_5.pdf"
//import backgroudImage2022 from"./images/BG_Page_2022.png";
import mobileBG2022 from "./images/Arithmetic_Genius_Contest_Mobile_BG.jpg";
import backgroudImage2022 from "./images/BG_2022_AGC.jpg";
import agc2023mobileLogo from "./images/Bg_2023_mobile.png";
import agc2023WebLogo from "./images/Bg_2023_web.png";
import agc2024mobileLogo from "./images/Bg_2024_mobile.png";
import agc2024WebLogo from "./images/Bg_2024_web.png";
import round1class1agc2024pdf from "./images/sample_question_paper/AGC_2024_R1_QP_Class_1.pdf";
import round1class2agc2024pdf from "./images/sample_question_paper/AGC_2024_R1_QP_Class_2.pdf";
import round1class3agc2024pdf from "./images/sample_question_paper/AGC_2024_R1_QP_Class_3.pdf";
import round1class4agc2024pdf from "./images/sample_question_paper/AGC_2024_R1_QP_Class_4.pdf";
import round1class5agc2024pdf from "./images/sample_question_paper/AGC_2024_R1_QP_Class_5.pdf";
import round2class1agc2024pdf from "./images/sample_question_paper/AGC_2024_R2_QP_Class_1.pdf";
import round2class2agc2024pdf from "./images/sample_question_paper/AGC_2024_R2_QP_Class_2.pdf";
import round2class3agc2024pdf from "./images/sample_question_paper/AGC_2024_R2_QP_Class_3.pdf";
import round2class4agc2024pdf from "./images/sample_question_paper/AGC_2024_R2_QP_Class_4.pdf";
import round2class5agc2024pdf from "./images/sample_question_paper/AGC_2024_R2_QP_Class_5.pdf";

/*import round3class1agc2023pdf from "./images/sample_question_paper/AGC_2023_R3_QP_Class_1.pdf";
import round3class2agc2023pdf from "./images/sample_question_paper/AGC_2023_R3_QP_Class_2.pdf";
import round3class3agc2023pdf from "./images/sample_question_paper/AGC_2023_R3_QP_Class_3.pdf";
import round3class4agc2023pdf from "./images/sample_question_paper/AGC_2023_R3_QP_Class_4.pdf";
import round3class5agc2023pdf from "./images/sample_question_paper/AGC_2023_R3_QP_Class_5.pdf";

import round4class1agc2023pdf from "./images/sample_question_paper/AGC_2023_R4_QP_Class_1.pdf";
import round4class2agc2023pdf from "./images/sample_question_paper/AGC_2023_R4_QP_Class_2.pdf";
import round4class3agc2023pdf from "./images/sample_question_paper/AGC_2023_R4_QP_Class_3.pdf";
import round4class4agc2023pdf from "./images/sample_question_paper/AGC_2023_R4_QP_Class_4.pdf";
import round4class5agc2023pdf from "./images/sample_question_paper/AGC_2023_R4_QP_Class_5.pdf";
*/
const useStyles = makeStyles(theme => ({
  root: {
    alignContent: "stretch",
    wrap: "nowrap",
    display: "flex"
  },
  paper: {
    width: "100%",
    height: "130vh",
    backgroundColor: "#02A550",
    backgroundImage: `url(${agc2024WebLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  mobilePaper: {
    width: "100%",
    height: "auto",
    backgroundImage: `url(${agc2024mobileLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  samplePaperTitle: {
    color: "white",
    textAlign: "center",
    marginTop: theme.spacing(3),
    fontWeight: "bold",
    fontSize: theme.spacing(4)
  },
  aboutAGCContent: {
    textAlign: "left",
    fontSize: theme.spacing(3),
    color: "#fafafa",
    marginTop: theme.spacing(2),
    whiteSpace: "pre-line"
  },
  aboutAGCBox: {
    border: "3px solid #E62127",
    borderRadius: "3px",
    padding: "3px 5px",
    textAlign: "center",
    shadowColor: "#F5E740",
    shadowOffset: {
      width: 0,
      height: 12
    },
    shadowOpacity: 0.58,
    shadowRadius: 16.0,
    whiteSpace: "pre-line",
    //color: "black",
    fontWeight: "bold",
    fontSize: "16px"
  },
  gridList: {
    width: "100%",
    height: "95%",
    overflow: "hidden",
    marginBottom: theme.spacing(1)
  },
  icon: {
    display: "inline-block",
    color: "white"
  },
  registrationPageLink: {
    textDecoration: "underline",
    color: "white",
    fontSize: "12px",
    fontWeight: "bold"
  },
  button: {
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: theme.spacing(1),
    display: "inline-block",
    textAlign: "center"
  },
  titleDeskTop: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: "15px",
    whiteSpace: "pre-line",
    display: "inline-block",
    fontSize: theme.spacing(2),
    padding: theme.spacing(3)
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#000000"
  }
}));
const SampleQuestionPaper = () => {
  const theme = useTheme();
  const classes = useStyles();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const title = "SAMPLE QUESTION PAPER FOR ALL ROUNDS";
  const mobileTitle = "Sample Question Paper for all Rounds";
  const register = "REGISTER NOW";
  const Class_1_sample_qn_pdf = "CLASS 1 PDF";
  const Class_2_sample_qn_pdf = "CLASS 2 PDF";
  const Class_3_sample_qn_pdf = "CLASS 3 PDF";
  const Class_4_sample_qn_pdf = "CLASS 4 PDF";
  const Class_5_sample_qn_pdf = "CLASS 5 PDF";
  const remarks = "* Number of Questions will vary for each Round.";
  const [state, setState] = React.useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: ""
  });
  const sampleQuestionPapers = [
    {
      img: Class_2_Sample_QP,
      title: "image1"
    },
    {
      img: Class_3_Sample_QP,
      title: "image2"
    },
    {
      img: Class_4_Sample_QP,
      title: "image3"
    },
    {
      img: Class_5_Sample_QP,
      title: "image4"
    }
  ];
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      setScreen();
      window.removeEventListener("resize", handleResize);
    };
  }, [smallScreen, displayedQuestionPaper, sampleQuestionPapers]);

  function handleResize() {
    setDisplayedQuestionPaper(sampleQuestionPapers.slice(0, 1));
  }
  function setScreen() {
    const sampleQuestionPapers = [
      {
        img: smallScreen ? Class_2_Mobile : Class_2_Sample_QP,
        title: "image1"
      },
      {
        img: smallScreen ? Class_3_Mobile : Class_3_Sample_QP,
        title: "image2"
      },
      {
        img: smallScreen ? Class_4_Mobile : Class_4_Sample_QP,
        title: "image3"
      },
      {
        img: Class_5_Sample_QP,
        title: "image4"
      }
    ];
  }

  let [displayedQuestionPaper, setDisplayedQuestionPaper] = React.useState(
    sampleQuestionPapers.slice(0, 1)
  );

  function getIndexOfImage(searchElement) {
    let positionOfElement = 0;
    sampleQuestionPapers.map((value, index) => {
      if (searchElement === value.title) {
        positionOfElement = index;
      }
    });
    return positionOfElement;
  }

  function handleClick(forwardClick) {
    let index;
    let arraySize = displayedQuestionPaper.length - 1;

    if (forwardClick) {
      index = getIndexOfImage(displayedQuestionPaper[arraySize].title);

      if (index + 1 >= sampleQuestionPapers.length) {
        setDisplayedQuestionPaper(sampleQuestionPapers.slice(2, 3));
      } else {
        index++;
        setDisplayedQuestionPaper(sampleQuestionPapers.slice(index, index + 1));
      }
    } else {
      index = getIndexOfImage(displayedQuestionPaper[0].title);
      if (index - 1 <= 0) {
        setDisplayedQuestionPaper(sampleQuestionPapers.slice(0, 1));
      } else {
        setDisplayedQuestionPaper(sampleQuestionPapers.slice(index - 1, index));
      }
    }
  }
  function QuestionPapersToBeDisplayed() {
    if (smallScreen) {
      return (
        <React.Fragment>
          <Grid item container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} style={{ textAlign: "left" }}>
              <br></br>
              <br></br>
              <div className={classes.aboutAGCBox}>
                <Typography className={classes.agcText}> Round 1</Typography>
              </div>
              <List style={{ textAlign: "justify" }}>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>

                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px",
                      display: "flex"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round1class1agc2024pdf}
                      download="class1.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_1_sample_qn_pdf}{" "}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round1class2agc2024pdf}
                      download="class2.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_2_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round1class3agc2024pdf}
                      download="class3.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_3_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>

                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round1class4agc2024pdf}
                      download="class4.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_4_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round1class5agc2024pdf}
                      download="class5.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_5_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
              </List>
              <div className={classes.aboutAGCBox}>
                <Typography className={classes.agcText}>Round 2</Typography>
              </div>
              <List>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round2class1agc2024pdf}
                      download="class1.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_1_sample_qn_pdf}{" "}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round2class2agc2024pdf}
                      download="class2.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_2_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round2class3agc2024pdf}
                      download="class3.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_3_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round2class4agc2024pdf}
                      download="class4.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_4_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round2class5agc2024pdf}
                      download="class5.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_5_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
              </List>

              {/*  <div className={classes.aboutAGCBox}>
                <Typography className={classes.agcText}>Round 3</Typography>
              </div>{" "}
              <List>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round3class1agc2023pdf}
                      download="class1.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_1_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round3class2agc2023pdf}
                      download="class2.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_2_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round3class3agc2023pdf}
                      download="class3.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_3_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round3class4agc2023pdf}
                      download="class4.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_4_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round3class5agc2023pdf}
                      download="class5.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_5_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
              </List>
              <div className={classes.aboutAGCBox}>
                <Typography className={classes.agcText}>Round 4</Typography>
              </div>
              <List>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round4class1agc2023pdf}
                      download="class1.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_1_sample_qn_pdf}{" "}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round4class2agc2023pdf}
                      download="class2.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_2_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round4class3agc2023pdf}
                      download="class3.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_3_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round4class4agc2023pdf}
                      download="class4.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_4_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid item md={3} xs={3} sm={3} lg={3} xl={3}></Grid>
                  <Button
                    style={{
                      width: smallScreen ? "55%" : "100%",
                      fontSize: smallScreen ? "10px" : "14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    title="Click here to Download!"
                  >
                    <a
                      href={round4class5agc2023pdf}
                      download="class5.pdf"
                      className={classes.registrationPageLink}
                    >
                      {Class_5_sample_qn_pdf}
                    </a>
                  </Button>
                </ListItem>
              </List>
                  */}
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid
              container
              xs={12}
              sm={12}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {/*<img src={agcDoll} alt="doll" />*/}
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Grid
            item
            container
            md={12}
            xs={12}
            lg={12}
            sm={12}
            xl={12}
            style={{ marginTop: theme.spacing(2) }}
          >
            <Grid
              item
              md={smallScreen ? 4 : 5}
              xs={smallScreen ? 4 : 5}
              lg={smallScreen ? 4 : 5}
              sm={smallScreen ? 4 : 5}
              xl={smallScreen ? 4 : 5}
            >
              {" "}
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item md={3} lg={3}></Grid>
            <Grid item md={6} lg={6}>
              <Grid item container justify="center" spacing={4}>
                <Grid item md={3} lg={3}>
                  <div className={classes.aboutAGCBox}>
                    <Typography className={classes.agcText}>
                      Round 1{" "}
                    </Typography>
                  </div>
                  <List>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round1class1agc2024pdf}
                          download="class1.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_1_sample_qn_pdf}{" "}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round1class2agc2024pdf}
                          download="class2.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_2_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round1class3agc2024pdf}
                          download="class3.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_3_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round1class4agc2024pdf}
                          download="class4.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_4_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>

                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round1class5agc2024pdf}
                          download="class5.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_5_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item md={3} lg={3} style={{ textAlign: "left" }}>
                  <div className={classes.aboutAGCBox}>
                    <Typography className={classes.agcText}>Round 2</Typography>
                  </div>
                  <List>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round2class1agc2024pdf}
                          download="class1.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_1_sample_qn_pdf}{" "}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round2class2agc2024pdf}
                          download="class2.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_2_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round2class3agc2024pdf}
                          download="class3.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_3_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round2class4agc2024pdf}
                          download="class4.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_4_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round2class5agc2024pdf}
                          download="class5.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_5_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                  </List>
                </Grid>
                {/* <Grid item md={3} lg={3} style={{ textAlign: "left" }}>
                  <div className={classes.aboutAGCBox}>
                    <Typography className={classes.agcText}>Round 3</Typography>
                  </div>
                  <List>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round3class1agc2023pdf}
                          download="class1.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_1_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round3class2agc2023pdf}
                          download="class2.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_2_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round3class3agc2023pdf}
                          download="class3.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_3_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round3class4agc2023pdf}
                          download="class4.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_4_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round3class5agc2023pdf}
                          download="class5.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_5_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                  </List>
                </Grid>

                <Grid item md={3} lg={3} style={{ textAlign: "left" }}>
                  <div className={classes.aboutAGCBox}>
                    <Typography className={classes.agcText}>Round 4</Typography>
                  </div>
                  <List>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round4class1agc2023pdf}
                          download="class1.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_1_sample_qn_pdf}{" "}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round4class2agc2023pdf}
                          download="class2.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_2_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round4class3agc2023pdf}
                          download="class3.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_3_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round4class4agc2023pdf}
                          download="class4.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_4_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{
                          width: smallScreen ? "55%" : "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                        title="Click here to Download!"
                      >
                        <a
                          href={round4class5agc2023pdf}
                          download="class5.pdf"
                          className={classes.registrationPageLink}
                        >
                          {Class_5_sample_qn_pdf}
                        </a>
                      </Button>
                    </ListItem>
                  </List>
                </Grid>
                      */}
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid
              container
              xs={12}
              sm={12}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {/*<img src={agcDoll} alt="doll" />*/}
            </Grid>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </Grid>
        </React.Fragment>
      );
    }
  }

  return (
    <div className={classes.root}>
      <Grid id="sampleQuestionPaper" container direction="row" spacing={2}>
        <Paper className={smallScreen ? classes.mobilePaper : classes.paper}>
          <Grid item container md={12} xs={12} lg={12} sm={12} xl={12}>
            <AppBar position="static" style={{ backgroundColor: "#E62127" }}>
              <Grid item md={3} xs={3} lg={3} sm={3} xl={3}></Grid>
              <Grid item md={4} xs={9} sm={9} lg={4} xl={4}>
                <img
                  src={sipAbacusLogo}
                  alt="logo"
                  height={smallScreen ? "60%" : "50%"}
                  width={smallScreen ? "60%" : "30%"}
                />
              </Grid>
              <Grid item md={5} xs={0} sm={0} lg={5} xl={5}></Grid>
            </AppBar>
          </Grid>
          <Grid item container>
            <Grid item md={1} xs={1} sm={1} lg={1} xl={1}></Grid>
            <Grid
              item
              md={2}
              xs={4}
              sm={4}
              lg={2}
              xl={2}
              className={classes.container}
              justifyContent="flex-start"
            >
              <img
                src={agcLogo}
                alt="Logo"
                style={{ height: smallScreen ? "80%" : "60%", width: "60%" }}
              />
            </Grid>

            <Grid item container md={12} xs={12} lg={12} sm={12} xl={12}>
              <Grid item md={4} xs={2} lg={4} sm={2} xl={4}>
                {" "}
              </Grid>
              <Grid
                item
                md={4}
                xs={8}
                lg={4}
                sm={8}
                xl={4}
                className={classes.titleDeskTop}
                style={{
                  marginTop: smallScreen ? theme.spacing(-1) : theme.spacing(-7)
                }}
              >
                {smallScreen ? mobileTitle : title}
              </Grid>
              <Grid item md={4} xs={2} lg={4} sm={2} xl={4}></Grid>
            </Grid>
            <Grid item container md={12} xs={12} lg={12} sm={12} xl={12}>
              <br></br>
              <br></br>
              <br></br>

              <QuestionPapersToBeDisplayed></QuestionPapersToBeDisplayed>
              <Grid item md={4} xs={2} lg={4} sm={2} xl={4}>
                {" "}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default SampleQuestionPaper;
